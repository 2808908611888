import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { LoaderService } from './LoaderService'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  activeRequests = 0

  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.activeRequests === 0) {
      console.log('SOW')
      this.loaderService.showLoader()
    }

    this.activeRequests++
    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--
        if (this.activeRequests === 0) {
          console.log('hide')
          this.loaderService.hideLoader()
        }
      }),
    )
  }
}
