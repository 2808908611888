import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'

@Injectable()
export class jwtAuthService {
  base_url1 = 'https://localhost:44383/api/'
  base_url = 'https://retailpos.azurewebsites.net/api/'

  constructor(private http: HttpClient) {}

  login(Email: string, Psd: string): Observable<any> {
    // console.log(this.http.post('/api/auth/login', { email, password }))
    // return this.http.post('/api/auth/login', { email, password })
    console.log('LoGin')
    return this.http.post(this.base_url + 'Login/LoginCheck', { Email, Psd })
  }

  register(email: string, password: string, name: string): Observable<any> {
    return this.http.post('/api/auth/register', { email, password, name })
  }

  currentAccount(): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            AccessToken: accessToken,
          },
        }
      : {}

    return this.http.get('/api/auth/account', params)
  }

  logout(): Observable<any> {
    return this.http.get('/api/auth/logout')
  }
}
