import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { catchError } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'
import { BehaviorSubject } from 'rxjs'
// import { Http, Response, Headers, RequestOptions } from "@angular/http";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  base_url1 = 'https://localhost:7222/api/'
  base_url3 = 'https://localhost:44373/api/'
  base_url = 'https://retailpos.azurewebsites.net/api/'
  base_url2 = 'https://uat-bizpos.azurewebsites.net/api/'
  base_url_pos = 'https://uat-bizpos.azurewebsites.net/api/'

  server_ip = 'http://localhost'
  dburl = 'http://localhost:8081/'
  constructor(private http: HttpClient) {}
  public storeid: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  public contactid: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  toFormData(formValue) {
    const formData = new FormData()
    for (const key of Object.keys(formValue)) {
      const value = formValue[key]
      // console.log(key, value)
      formData.append(key, value)
    }
    return formData
  }
  //---------------------------

  GetClsoingCharges(CompanyId, TransDate) {
    return this.http.get(
      this.base_url + 'Company/GetClosingCharges?CompanyId=' + CompanyId + '&Date=' + TransDate,
    )
  }

  AddClosingCharges(payload) {
    return this.http.post(this.base_url + 'Company/SaveClosingCharges', payload)
  }

  AccountClosingSave(payload) {
    return this.http.post(this.base_url + 'Company/SaveAccClosing', payload)
  }

  AddStoreOnlinePayment(payload) {
    return this.http.post(this.base_url + 'Company/AddStoreOnlineAmt', payload)
  }

  GetAccClosingOnline(companyId, TransDate) {
    return this.http.get(
      this.base_url +
        'Company/GetAccClosingOnline?companyId=' +
        companyId +
        '&TransDate=' +
        TransDate,
    )
  }

  //---------------------------

  Get_Transaction_Details(companyid, transid) {
    return this.http.get(
      this.base_url +
        'Company/Get_Transaction_Details?companyid=' +
        companyid +
        '&transid=' +
        transid,
    )
  }

  getemployee(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'Login/GetEmployee?CompanyId=' + CompanyId + '&StoreId=' + StoreId,
    )
  }
  GetSTSTransSend(companyId, storeId, sender, receiver, Date, transId, status) {
    return this.http.get(
      this.base_url +
        'STS/GetSTStransSend?companyId=' +
        companyId +
        '&storeId=' +
        storeId +
        '&sender=' +
        sender +
        '&receiver=' +
        receiver +
        '&Date=' +
        Date +
        '&transId=' +
        transId +
        '&status=' +
        status,
    )
  }

  GetSTSReceive(companyId, storeId, sender, receiver, Date, transId, status) {
    return this.http.get(
      this.base_url +
        'STS/GetSTStransReceive?companyId=' +
        companyId +
        '&storeId=' +
        storeId +
        '&sender=' +
        sender +
        '&receiver=' +
        receiver +
        '&Date=' +
        Date +
        '&transId=' +
        transId +
        '&status=' +
        status,
    )
  }
  GetFbAccSales(CompanyId, TransDate) {
    return this.http.get(
      this.base_url + 'Company/GetFbadSales?CompanyId=' + CompanyId + '&TransDate=' + TransDate,
    )
  }

  getpostransamount(companyId, date) {
    return this.http.get(
      this.base_url + 'Company/GetPOSCashSales?companyId=' + companyId + '&Date=' + date,
    )
  }
  GetSingleStore(id) {
    return this.http.get(this.base_url + 'Store/GetCompanies?id=' + id)
  }

  GetPurchaseProducts(CompanyId, Product, Vendor, fromAmt, toAmt, fromDate, toDate, Invoice) {
    return this.http.get(
      this.base_url +
        'Company/GetPurchaseProducts?CompanyId=' +
        CompanyId +
        '&ProductId=' +
        Product +
        '&VendorId=' +
        Vendor +
        '&fromamt=' +
        fromAmt +
        '&toamt=' +
        toAmt +
        '&fromdate=' +
        fromDate +
        '&todate=' +
        toDate +
        '&Invoice=' +
        Invoice,
    )
  }

  //Salary

  // Salary

  Get_Employees(companyid, storeid) {
    return this.http.get(
      this.base_url + 'Company/Salary_Get_Employees?companyid=' + companyid + '&storeid=' + storeid,
    )
  }

  GetBankAccountsByStore(companyid, bankid) {
    return this.http.get(
      this.base_url +
        'Company/Salary_GetBankAccountsByStore?companyid=' +
        companyid +
        '&bankid=' +
        bankid,
    )
  }
  Get_Salary_index(companyid, empid, storeid, fromamt, toamt, fromdate, todate) {
    return this.http.get(
      this.base_url +
        'Company/Get_Salary_index?companyid=' +
        companyid +
        '&empid=' +
        empid +
        '&storeid=' +
        storeid +
        '&fromamt=' +
        fromamt +
        '&toamt=' +
        toamt +
        '&fromdate=' +
        fromdate +
        '&todate=' +
        todate,
    )
  }
  Salary_Save(payload) {
    return this.http.post(this.base_url + 'Company/Salary_Save', payload)
  }
  Update_salary(payload) {
    return this.http.post(this.base_url + 'Company/Salary_Update', payload)
  }
  Salary_Delete(transid) {
    return this.http.get(this.base_url + 'Company/Salary_Delete?transid=' + transid)
  }

  // STS DEL
  DeleteSTS(TransId) {
    return this.http.get(this.base_url + 'STS/DeleteSTS?TransId=' + TransId)
  }
  //Employee Credit
  GetEmpCredit(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'Login/GetEmployeeCredit?CompanyId=' + CompanyId + '&StoreId=' + StoreId,
    )
  }

  //Employee Credit POS
  GetCredPointOrder(Date, StoreId) {
    return this.http.get(
      this.base_url_pos + 'Customer/CreditPointIndex?Date=' + Date + '&StoreId=' + StoreId,
    )
  }

  // StockUpdate Auto Order
  GetStockUpdIndexAuto(StoreId, date, catId, supId, prodname, proddes) {
    return this.http.get(
      this.base_url +
        'Stock/GetStockUpdIndexAuto?storeId=' +
        StoreId +
        '&date=' +
        date +
        '&catId=' +
        catId +
        '&supId=' +
        supId +
        '&prodname=' +
        prodname +
        '&proddes=' +
        proddes,
    )
  }
  SaveCreditPoints(payload) {
    return this.http.post(this.base_url_pos + 'Customer/SaveCreditPoints', payload)
  }
  UpdateCreditPoint(payload) {
    return this.http.post(this.base_url_pos + 'Customer/UpdateCreditPoints', payload)
  }
  //Product Analaysis

  //PurchaseProd

  AddPurchaseProd(payload) {
    return this.http.post(this.base_url + 'Company/AddPurchaseProd', payload)
  }

  GetPurchaseProd(companyId, product, frmamt, toamt, vendor, frmdate, todate) {
    return this.http.get(
      this.base_url +
        'Company/GetPurchaseProd?companyId=' +
        companyId +
        '&product=' +
        product +
        '&frmamt=' +
        frmamt +
        '&toamt=' +
        toamt +
        '&vendor=' +
        vendor +
        '&frmdate=' +
        frmdate +
        '&todate=' +
        todate,
    )
  }

  getpurprod(companyId) {
    return this.http.get(this.base_url + 'Company/Getpurchseprod?CompanyId=', companyId)
  }

  getpurvend(companyId) {
    return this.http.get(this.base_url + 'Company/Getpurchsevend?CompanyId=', companyId)
  }

  //Company

  GetCompanyDet(CompanyId) {
    return this.http.get(this.base_url + 'Company/GetCompanyIndex?companyId=' + CompanyId)
  }

  SaveCompany(formdata) {
    var body = this.toFormData(formdata)
    return this.http.post(this.base_url + 'Company/SaveData', body)
  }

  //Customer

  SaveCustomer(payload) {
    return this.http.post(this.base_url + 'Company/AddCustomer', payload)
  }

  CustomerIndex(compid) {
    return this.http.get(this.base_url + 'Company/Customer_Index?compid=' + compid)
  }

  CustomerDetails(custid) {
    return this.http.get(this.base_url + 'Company/CustomerDetailsIndex?custid=' + custid)
  }

  deletecustomer(payload) {
    return this.http.post(this.base_url + 'Company/DeleteCustomer', payload)
  }

  UpdateCustomerErp(payload) {
    return this.http.post(this.base_url + 'Company/UpdateCustomer', payload)
  }

  //Bank Account

  AddbankAccount(data) {
    return this.http.post(this.base_url + 'Company/AddBankaccount', data)
  }

  GetBankAccounts(companyId) {
    return this.http.get(this.base_url + 'Company/GetBankAccounts?companyId=' + companyId)
  }

  UpdateBankAccount(data) {
    return this.http.post(this.base_url + 'Company/updateBankAcc', data)
  }

  //Deposit & Withdrawal

  BankDepDrawIndex(
    compid,
    transtype,
    transmode,
    amtstr,
    amtend,
    respid,
    multiact,
    frmdate,
    todate,
    storeid,
    type,
  ) {
    return this.http.get(
      this.base_url +
        'ERP/bankdepwithdrawIndex?compid=' +
        compid +
        '&transtype=' +
        transtype +
        '&transmode=' +
        transmode +
        '&amtstr=' +
        amtstr +
        '&amtend=' +
        amtend +
        '&respid=' +
        respid +
        '&multiact=' +
        multiact +
        '&frmdate=' +
        frmdate +
        '&todate=' +
        todate +
        '&storeid=' +
        storeid +
        '&type=' +
        type,
    )
  }

  savedepwithdraw(payload) {
    return this.http.post(this.base_url + 'ERP/SaveDepWithdraw', payload)
  }

  DeleteDepwithdraw(transid, transmode, balance, contact) {
    return this.http.get(
      this.base_url +
        'ERP/DeleteDepwithdraw2?transid=' +
        transid +
        '&transmode=' +
        transmode +
        '&balance=' +
        balance +
        '&contact=' +
        contact,
    )
  }

  UpdateBankDepwithDraw(payload) {
    return this.http.post(this.base_url + 'ERP/UpdateBankDepwithDraw', payload)
  }

  //Money Transfer

  GetAllBankAccount() {
    return this.http.get(this.base_url + 'ERP/GetAllBankAccounts')
  }

  SaveMoneytrans(payload) {
    return this.http.post(this.base_url + 'ERP/SaveAccount', payload)
  }

  GetBankTransIndex(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'ERP/GetBankTransIndex?companyId=' + CompanyId + '&storeId=' + StoreId,
    )
  }

  GetBankTransDetails(TransId) {
    return this.http.get(this.base_url + 'ERP/GetBankTransDetails?TransId=' + TransId)
  }

  UpdatedBankTrans(payload) {
    return this.http.post(this.base_url + 'ERP/SaveEditBankTrans', payload)
  }

  DeleteBankTrans(id) {
    return this.http.get(this.base_url + 'ERP/DeleteMoneyTrans?accId=' + id)
  }
  //Swiping Machine

  SaveSwipeMachine(payload) {
    return this.http.post(this.base_url + 'Company/SaveSwipMachine', payload)
  }
  GetSwipeMachine(companyId, storeId, accno, machid) {
    return this.http.get(
      this.base_url +
        'Company/GetSwipeMachineIndex?companyId=' +
        companyId +
        '&storeId=' +
        storeId +
        '&accNo=' +
        accno +
        '&machId=' +
        machid,
    )
  }
  GetEditSwipe(astId) {
    return this.http.get(this.base_url + 'Company/EditByIdSwipe?astId=' + astId)
  }
  UpdateSwipe(payload) {
    return this.http.post(this.base_url + 'Company/SaveEditSwipe', payload)
  }
  ActiveAsset(astId) {
    return this.http.get(this.base_url + 'Company/AssetAct?Id=' + astId)
  }

  getemploye(CompanyId) {
    return this.http.get(this.base_url + 'Menu/GetEmployees?CompanyId=' + CompanyId)
  }

  addrecycle(objdata) {
    return this.http.post(this.base_url + 'Menu/Addrecycle', objdata)
  }

  editrecycle(objdata) {
    return this.http.post(this.base_url + 'Menu/Editrecycle', objdata)
  }

  getrecycleindex(
    CompanyId,
    storeId,
    famt,
    toamt,
    frmdate,
    todate,
    referby,
    transId,
    contTypeId,
    ContId,
  ) {
    return this.http.get(
      this.base_url +
        'Menu/GetRecycleIndex?companyId=' +
        CompanyId +
        '&storeId=' +
        storeId +
        '&famt=' +
        famt +
        '&toamt=' +
        toamt +
        '&frmdate=' +
        frmdate +
        '&todate=' +
        todate +
        '&referby=' +
        referby +
        '&transId=' +
        transId +
        '&contTypeId=' +
        contTypeId +
        '&ContId=' +
        ContId,
    )
  }

  deleterecycle(transid) {
    return this.http.get(this.base_url + 'Menu/DeletTrans?transId=' + transid)
  }

  //Payment Type

  GetPayment(CompanyId) {
    return this.http.get(this.base_url + 'Company/GetPaymentTypeIndex?CompanyId=' + CompanyId)
  }

  AddPaymentTypes(payment) {
    return this.http.post(this.base_url + 'Company/AddPaymentTypes', payment)
  }

  UpdatePaymentTypes(payment) {
    return this.http.post(this.base_url + 'Company/UpdatePaymentType', payment)
  }

  PaymentisActive(paymentid) {
    return this.http.get(this.base_url + 'Company/PaymentTypeActive?paymentid=' + paymentid)
  }

  //Asset

  GetAssets(companyId, vendorId, asttypeId, storeId, asset) {
    return this.http.get(
      this.base_url +
        'Company/GetAssets?companyId=' +
        companyId +
        '&vendorId=' +
        vendorId +
        '&asttypeId=' +
        asttypeId +
        '&storeId=' +
        storeId +
        '&asset=' +
        asset,
    )
  }

  AddAssets(data) {
    return this.http.post(this.base_url + 'Company/AddAssets', data)
  }

  UpdateAssets(data) {
    return this.http.post(this.base_url + 'Company/updateAsset', data)
  }

  GetAstTypes(companyId) {
    return this.http.get(this.base_url + 'Company/GetAstTypes?companyId=' + companyId)
  }

  GetAsTypeIsActive(id) {
    return this.http.get(this.base_url + 'Company/Ass_TypeDeactive?id=' + id)
  }

  GetAstDeactive(id) {
    return this.http.get(this.base_url + 'Company/AssetDeactive?id=' + id)
  }

  GetAssetDelete(id) {
    return this.http.get(this.base_url + 'Company/AssetDelete?id=' + id)
  }

  GetAssetTypeDelete(id) {
    return this.http.get(this.base_url + 'Company/AssetTypeDelete?id=' + id)
  }

  //Asset Type

  GetAssetTypes(companyId) {
    return this.http.get(this.base_url + 'Company/GetAssettypes?companyId=' + companyId)
  }

  AddAssetTypes(data) {
    return this.http.post(this.base_url + 'Company/AddAssettypes', data)
  }
  UpdateAssetTypes(data) {
    return this.http.post(this.base_url + 'Company/Updateassettypes', data)
  }

  //Maintanance

  Getmaintanance(CompanyId, storeId, asset, vendor, assetType, Mainttype, indexType) {
    return this.http.get(
      this.base_url +
        'Company/Getmaintanance?companyId=' +
        CompanyId +
        '&storeId=' +
        storeId +
        '&asset=' +
        asset +
        '&vendor=' +
        vendor +
        '&assetType=' +
        assetType +
        '&Mainttype=' +
        Mainttype +
        '&indexType=' +
        indexType,
    )
  }

  GetBills(companyId, date, asset, vendor, storeId) {
    return this.http.get(
      this.base_url +
        'Company/GetBills?companyId=' +
        companyId +
        '&date=' +
        date +
        '&asset=' +
        asset +
        '&vendor=' +
        vendor +
        '&storeId=' +
        storeId,
    )
  }

  Updatebills(payload) {
    return this.http.post(this.base_url + 'Company/UpdateBills', payload)
  }

  GetAst(companyId, storeId) {
    return this.http.get(
      this.base_url + 'Company/GetAst?companyId=' + companyId + '&StoreId=' + storeId,
    )
  }

  GetMaintBillById(MBTid) {
    return this.http.get(this.base_url + 'Company/GetAstByMaintBillId?MaintBillId=' + MBTid)
  }
  DeleteGBills(payload) {
    return this.http.post(this.base_url + 'Company/DeleteGeneratedBill', payload)
  }

  GetCompanyAst(companyId) {
    return this.http.get(this.base_url + 'Company/GetCompanyAst?companyId=' + companyId)
  }

  AddMaintBill(payload) {
    return this.http.post(this.base_url + 'Company/AddMaintbill', payload)
  }

  Getmaintbilltypes(companyId) {
    return this.http.get(this.base_url + 'Company/Getmaintbilltype?companyId=' + companyId)
  }

  Generatebills(payload) {
    return this.http.post(this.base_url + 'Company/Generatebills', payload)
  }

  AddMaintanance(payload) {
    return this.http.post(this.base_url + 'Company/AddMaintanance', payload)
  }

  UpdateMaintBill(payload) {
    return this.http.post(this.base_url + 'Company/UpdateMaintBill', payload)
  }

  GetAssettypebyasset(astTypeId) {
    return this.http.get(this.base_url + 'Company/GetAsttypebyAst?astTypeId=' + astTypeId)
  }

  //Maintanance Bill Type
  getmaintbill(CompanyId) {
    return this.http.get(this.base_url + 'Company/GetMaintBillTypes?companyId=' + CompanyId)
  }

  SaveMaintbill(maintBillType) {
    return this.http.post(this.base_url + 'Company/AddMaintBillType', maintBillType)
  }

  GetDeactivate(Id) {
    return this.http.get(this.base_url + 'Company/Deactivate?id=' + Id)
  }

  GetMaintBillTypeById(Id) {
    return this.http.get(this.base_url + 'Company/GetMaintBillTypeById?id=' + Id)
  }

  UpdateMaintbill(maintBillType) {
    return this.http.post(this.base_url + 'Company/UpdateMaintBillType', maintBillType)
  }

  //Purchase&Maintanance

  PurchaseMaintIndex(
    billtype,
    billstatus,
    compid,
    billid,
    vendorid,
    paylocid,
    recloc,
    frmdate,
    todate,
    amtstr,
    amtend,
  ) {
    return this.http.get(
      this.base_url +
        'ERP/PurchaseMaintIndex?billtype=' +
        billtype +
        '&billstatus=' +
        billstatus +
        '&compid=' +
        compid +
        '&billid=' +
        billid +
        '&vendorid=' +
        vendorid +
        '&amtstr=' +
        amtstr +
        '&amtend=' +
        amtend +
        '&frmdate=' +
        frmdate +
        '&todate=' +
        todate +
        '&paylocid=' +
        paylocid +
        '&recloc=' +
        recloc,
    )
  }

  GetReferenceList(compid) {
    return this.http.get(this.base_url + 'ERP/GetReferenceList?compid=' + compid)
  }

  GetStorePaymentType(compid, storeid) {
    return this.http.get(
      this.base_url + 'ERP/GetStorePaymentType?compid=' + compid + '&storeid=' + storeid,
    )
  }

  SavePurchMaintBill(payload) {
    return this.http.post(this.base_url + 'ERP/SavePurchMaintBill', payload)
  }

  BillPayTransValues(billid) {
    return this.http.get(this.base_url + 'ERP/BillPayTransValues?billid=' + billid)
  }

  BillPaySingleTrans(transId) {
    return this.http.get(this.base_url + 'ERP/BillPaySingleTrans?transId=' + transId)
  }

  EditPurchaseMaint(payload) {
    return this.http.post(this.base_url + 'ERP/EditPurchaseMaint', payload)
  }

  EditpayTansDelete(transid, billid, orderid, BankId) {
    return this.http.get(
      this.base_url +
        'ERP/DeleteTransBillPay?transid=' +
        transid +
        '&billid=' +
        billid +
        '&orderid=' +
        orderid +
        '&BankId=' +
        BankId,
    )
  }

  //Dayclosing
  Gettranstypes(companyId, storeId, date) {
    return this.http.get(
      this.base_url +
        'Store/GetOnlineAssets?companyId=' +
        companyId +
        '&storeId=' +
        storeId +
        '&date=' +
        date,
    )
  }
  GetPoscash(storeid, date) {
    return this.http.get(
      this.base_url + 'Store/GetPOSDayClosing?storeId=' + storeid + '&date=' + date,
    )
  }
  GetPOSPaymt(storeId, companyId, from, to) {
    return this.http.get(
      this.base_url2 +
        'Receipt/storepaymentsbytype?storeid=' +
        storeId +
        '&companyid=' +
        companyId +
        '&from=' +
        from +
        '&to=' +
        to,
    )
  }

  GetPOSspt(storeId) {
    return this.http.get(this.base_url2 + 'Receipt/spt?storeid=' + storeId)
  }

  SaveDayclosing(payload) {
    return this.http.post(this.base_url + 'Store/SaveDayClosing', payload)
  }

  // Cash Closing
  //AccountClosing
  GetAccountInd(companyId, storeId, date) {
    return this.http.get(
      this.base_url +
        'Company/GetAccountClosIndex?CompanyId=' +
        companyId +
        '&StoreId=' +
        storeId +
        '&Date=' +
        date,
    )
  }

  GetAccounttrans(companyId, storeId, date, paytypeid) {
    return this.http.get(
      this.base_url +
        'Company/GetAccountClosTrans?CompanyId=' +
        companyId +
        '&StoreId=' +
        storeId +
        '&Date=' +
        date +
        '&PaytypeId=' +
        paytypeid,
    )
  }

  GetClosingTrans(CompanyId, StoreId, Date) {
    return this.http.get(
      this.base_url +
        'Company/GetClosingTrans?CompanyId=' +
        CompanyId +
        '&StoreId=' +
        StoreId +
        '&Date=' +
        Date,
    )
  }

  SaveAccClosing(payload) {
    return this.http.post(this.base_url + 'Company/SaveAccountClosing', payload)
  }

  getBankClsng(CompanyId, StoreId, Date, PaytypeId, bankAccId) {
    return this.http.get(
      this.base_url +
        'Company/GetBankClosing?CompanyId=' +
        CompanyId +
        '&StoreId=' +
        StoreId +
        '&Date=' +
        Date +
        '&PaytypeId=' +
        PaytypeId +
        '&bankAccId=' +
        bankAccId,
    )
  }
  //Employee
  GetEmpRolesValues(compid) {
    return this.http.get(this.base_url + 'Menu/GetEmpRoles?compid=' + compid)
  }
  GetEmployeeIndex(
    compid,
    empid,
    empstoreid,
    empname,
    empdepart,
    empdegnt,
    emptoplist,
    empvisibility,
  ) {
    return this.http.get(
      this.base_url +
        'Menu/GetEmployeeIndex?compid=' +
        compid +
        '&empid=' +
        empid +
        '&empstoreid=' +
        empstoreid +
        '&empname=' +
        empname +
        '&empdepart=' +
        empdepart +
        '&empdegnt=' +
        empdegnt +
        '&emptoplist=' +
        emptoplist +
        '&empvisibility=' +
        empvisibility,
    )
  }

  Saveattancevalues(payload) {
    const formData: FormData = new FormData() // this.toFormData({ objData: payload })
    for (const key of Object.keys(payload)) {
      const value = payload[key]
      // console.log(key, value)
      formData.append(key, value)
    }
    let files: File[] = payload.fileList
    delete payload.fileList
    formData.append('image', files[0])
    formData.append('proof', files[1])
    // files.forEach(file => {
    //   formData.append('images[]', file)
    // })
    return this.http.post(this.base_url + 'Menu/AddEmployee', formData)
  }

  //Add Emp

  uploadImage(image: Blob): Observable<any> {
    const uploadEndpoint = 'https://biz1ps.azurewebsites.net/api/Product/koppupathivetram'
    const formData: FormData = new FormData()
    formData.append('file', image)

    return this.http.post(uploadEndpoint, formData)
  }

  GetEnumsHeaderValues() {
    return this.http.get(this.base_url + 'Menu/GetEnumsHeaderIndex')
  }

  GetEnumsValues(ColumnName) {
    return this.http.get(this.base_url + 'Menu/GetEnums?ColumnName=' + ColumnName)
  }

  updateempimgaes1(empid, imgurl) {
    return this.http.get(
      this.base_url + 'Menu/updateempimgaes1?empid=' + empid + '&imgurl=' + imgurl,
    )
  }

  updateempimgaes2(empid, proofurl) {
    return this.http.get(
      this.base_url + 'Menu/updateempimgaes2?empid=' + empid + '&proofurl=' + proofurl,
    )
  }

  EmployeeDetailsIndex(empid) {
    return this.http.get(this.base_url + 'Menu/EmployeeDetailsIndex?empid=' + empid)
  }

  GetStaDistEnumsHeaderIndex() {
    return this.http.get(this.base_url + 'Menu/GetStaDistEnumsHeaderIndex')
  }

  GetStaDistEnums(ColumnName) {
    return this.http.get(this.base_url + 'Menu/GetStaDistEnums?ColumnName=' + ColumnName)
  }

  getstores_emp(CompanyId) {
    return this.http.get(this.base_url + 'Store/GetStoresUnique?companyId=' + CompanyId)
  }

  EmployeeDeginationsValues(emproleid) {
    return this.http.get(this.base_url + 'Menu/EmployeeDeginations?emproleid=' + emproleid)
  }

  //Edit Employee
  Employact(Id) {
    return this.http.get(this.base_url + 'Menu/EmployeAct?Id=' + Id)
  }
  GetEditEmp(Id) {
    return this.http.get(this.base_url + 'Menu/EmployeEditGet?Id=' + Id)
  }
  SaveEditEmp(payload) {
    return this.http.post(this.base_url + 'Menu/EditEmployeeSave', payload)
  }

  //Import Employee
  EXPO_EMPLOYEE(payload) {
    return this.http.post(this.base_url + 'Menu/ImportEmployees', payload)
  }

  //Emp ROles
  getEmpRoles(companyId) {
    return this.http.get(this.base_url + 'Company/GetEmpRoles?companyId=' + companyId)
  }

  AddEmpRole(data) {
    return this.http.post(this.base_url + 'Company/AddEmpRole', data)
  }

  UpdateEmpRole(data) {
    return this.http.post(this.base_url + 'Company/UpdateEmprole', data)
  }

  //User

  GetRoleIndex() {
    return this.http.get(this.base_url + 'Company/GetRoles?companyId=')
  }

  GetuserIndex(CompanyId) {
    return this.http.get(this.base_url + 'Company/GetuserIndex?companyId=' + CompanyId)
  }

  AddUser_UpdatedUser(payload) {
    return this.http.post(this.base_url + 'Company/AddUser_UpdatedUsers', payload)
  }
  DeleteUser(Id) {
    return this.http.get(this.base_url + 'Company/Deleteuser?Id=' + Id)
  }

  GetEditUserById(Id) {
    return this.http.get(this.base_url + 'Company/GetUserById?id=' + Id)
  }

  // New Auth --> Start
  registration(payload) {
    return this.http.post(this.base_url + 'Login/Register', payload)
  }

  login(payload) {
    return this.http.post(this.base_url + 'Login/LoginCheck', payload)
  }

  PinLogin(Pin, CompanyId) {
    return this.http.get(this.base_url + 'Login/pinlogin?pin=' + Pin + '&companyid=' + CompanyId)
  }

  Lggetstore(CompanyId, StoreId, UserId) {
    return this.http.get(
      this.base_url +
        'Store/GetStores?companyId=' +
        CompanyId +
        '&id=' +
        StoreId +
        '&UserId=' +
        UserId,
    )
  }
  getstore(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'Store/GetStores?companyId=' + CompanyId + '&id=' + StoreId,
    )
  }

  GetFactoryStores(CompanyId) {
    return this.http.get(this.base_url + 'Store/GetFactoryStores?companyId=' + CompanyId)
  }

  GetProducts(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'ERP/GetProducts?companyId=' + CompanyId + '&storeId=' + StoreId,
    )
  }

  //Internal Transfer Advance Search Q_02-11-2023

  GetIndexvalues(companyid, orderId, fromDate, toDate, supplierId, receiverId, orderStatusId) {
    return this.http.get(
      this.base_url +
        'ERP/GetInternalIndex?companyId=' +
        companyid +
        '&orderId=' +
        orderId +
        '&fromDate=' +
        fromDate +
        '&toDate=' +
        toDate +
        '&supplierId=' +
        supplierId +
        '&receiverId=' +
        receiverId +
        '&orderStatusId=' +
        orderStatusId,
    )
  }
  GetIndexvaluesdis(companyid, orderId, fromDate, toDate, supplierId, receiverId, orderStatusId) {
    return this.http.get(
      this.base_url +
        'ERP/GetInternalIndexDis?companyId=' +
        companyid +
        '&orderId=' +
        orderId +
        '&fromDate=' +
        fromDate +
        '&toDate=' +
        toDate +
        '&supplierId=' +
        supplierId +
        '&receiverId=' +
        receiverId +
        '&orderStatusId=' +
        orderStatusId,
    )
  }
  GetIndexvaluesrec(companyid, orderId, fromDate, toDate, supplierId, receiverId, orderStatusId) {
    return this.http.get(
      this.base_url +
        'ERP/GetInternalIndexRec?companyId=' +
        companyid +
        '&orderId=' +
        orderId +
        '&fromDate=' +
        fromDate +
        '&toDate=' +
        toDate +
        '&supplierId=' +
        supplierId +
        '&receiverId=' +
        receiverId +
        '&orderStatusId=' +
        orderStatusId,
    )
  }

  EditInternal(oid, cid, data) {
    return this.http.post(this.base_url + 'ERP/oi_update_Ord?oid=' + oid + '&cid=' + cid, data)
  }

  // --Internal new
  GetInternalValues(ordId) {
    return this.http.get(this.base_url + 'ERP/GetInternalValues?ordId=' + ordId)
  }

  UpdateInternalOrder(data) {
    return this.http.post(this.base_url + 'ERP/UpdateInternalOrder', data)
  }

  //---HYper's IO Edit

  GetDispatchedView(ordid) {
    return this.http.get(this.base_url + 'ERP/GetDispatchedView?ordid=' + ordid)
  }

  GetRecQtyValue(recQty) {
    return this.http.post(this.base_url + 'ERP/GetRecQtyValue', recQty)
  }

  getOIDispatch(oid) {
    return this.http.get(this.base_url + 'ERP/getOrderitems?oid=' + oid)
  }

  getproducts4update(cid) {
    return this.http.get(this.base_url + 'ERP/getProducts_4update?cid=' + cid)
  }

  saveDispatch(oid, cid, data) {
    return this.http.post(this.base_url + 'ERP/oi_update?oid=' + oid + '&cid=' + cid, data)
  }

  getOIDitem(oid) {
    return this.http.get(this.base_url + 'ERP/getoid_item?cid=' + oid)
  }

  deleteItem_hyper(ordid) {
    return this.http.get(this.base_url + 'ERP/GetDeleteOrder?ordid=' + ordid)
  }

  SaveIntOrd(payload) {
    return this.http.post(this.base_url + 'ERP/SaveInternal', payload)
  }

  GetOrderedProducts(CompanyId, StoreId) {
    return this.http.get(
      // this.base_url + 'ERP/GetDispatchProducts?companyId=' + CompanyId + '&storeId=' + StoreId,
      this.base_url + 'ERP/GetOrderedProducts?companyId=' + CompanyId + '&storeId=' + StoreId,
    )
  }

  GetDispatch(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'ERP/GetDispatchProducts?companyId=' + CompanyId + '&storeId=' + StoreId,
    )
  }

  //DIspatch Index
  GetDispatchIndex(CompanyId, StoreId, OrdDate) {
    return this.http.get(
      this.base_url +
        'ERP/GetDispatchIndex?CompanyId=' +
        CompanyId +
        '&StoreId=' +
        StoreId +
        '&FromDate=' +
        OrdDate,
    )
  }

  GetOrdDetail(OrderId) {
    return this.http.get(this.base_url + 'ERP/GetOrderDetail?OrdId=' + OrderId)
  }

  SaveDisptach(OrderId) {
    return this.http.get(this.base_url + 'ERP/SaveDispatch?OrdId=' + OrderId)
  }

  GetReceive(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'ERP/GetReceiveProd?companyId=' + CompanyId + '&storeId=' + StoreId,
    )
  }

  SaveReceive(OrderId) {
    return this.http.get(this.base_url + 'ERP/SaveReceive?OrdId=' + OrderId)
  }

  //PURCHASENTRY

  getbyid(OrdId) {
    return this.http.get(this.base_url + 'ERP/GetbyordId?OrdId=' + OrdId)
  }

  updatepurchase(payload) {
    return this.http.post(this.base_url + 'ERP/EditPurchase', payload)
  }

  Getvendors(CompanyId) {
    return this.http.get(this.base_url + 'Menu/getVendor_retail?companyId=' + CompanyId)
  }

  SavePurchase(payload) {
    return this.http.post(this.base_url + 'ERP/SavePurchase', payload)
  }

  Getstorepayment(StoreId) {
    return this.http.get(this.base_url + 'Menu/getStorePaymentType_Retail?storeId=' + StoreId)
  }

  GetProductspurchase(CompanyId) {
    return this.http.get(this.base_url + 'Menu/getProducts_retail?companyId=' + CompanyId)
  }

  Getpurchaseorder(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'ERP/GetPurchaseOrder?companyId=' + CompanyId + '&storeid=' + StoreId,
    )
  }

  GetPurchaseReceivedorder(CompanyId, StoreId, date) {
    return this.http.get(
      this.base_url +
        'ERP/GetPurchaseReceivedOrder?companyId=' +
        CompanyId +
        '&storeid=' +
        StoreId +
        '&date=' +
        date,
    )
  }

  GetPurchaseordDet(OrderId) {
    return this.http.get(this.base_url + 'ERP/GetPurchaseordDet?OrdId=' + OrderId)
  }

  SaveBillReceive(OrdId) {
    return this.http.get(this.base_url + 'ERP/SaveReceivePurc?OrdId=' + OrdId)
  }

  deletepurchase(OrdId) {
    return this.http.get(this.base_url + 'ERP/deletepurchase?OrdId=' + OrdId)
  }

  DeleteReceiveOrd(payload) {
    return this.http.post(this.base_url + 'ERP/DeleteReceiveOrd', payload)
  }

  //BillPayByVendor

  GetBillPay(CompanyId, StoreId, VendId, FromAmt, ToAmount, fromdate, Todate, Billid, Billstatus) {
    return this.http.get(
      this.base_url +
        'ERP/GetBillPayVenInd?CompanyId=' +
        CompanyId +
        '&StoreId=' +
        StoreId +
        '&vendId=' +
        VendId +
        '&frmAmt=' +
        FromAmt +
        '&toAmt=' +
        ToAmount +
        '&frmdate=' +
        fromdate +
        '&todate=' +
        Todate +
        '&billid=' +
        Billid +
        '&billstatus=' +
        Billstatus,
    )
  }

  GetBillPayVenById(CompanyId, VendorId) {
    return this.http.get(
      this.base_url + 'ERP/GetBillPayVenById?companyId=' + CompanyId + '&vendorId=' + VendorId,
    )
  }

  SaveBillPay(payload) {
    return this.http.post(this.base_url + 'ERP/SaveBillByVend', payload)
  }

  GetEditBillPayVendor(CompanyId, TransId, VendorId) {
    return this.http.get(
      this.base_url +
        'ERP/GetEditBillPayVen?companyId=' +
        CompanyId +
        '&transId=' +
        TransId +
        '&vendorId=' +
        VendorId,
    )
  }

  SaveEditBillPayVendor(payload) {
    return this.http.post(this.base_url + 'ERP/EditBillPayVendor', payload)
  }

  getDeleteBillPayVend(TransId, CompanyId, Amount) {
    return this.http.get(
      this.base_url +
        'ERP/DeleteBillPayVend?transid=' +
        TransId +
        '&companyId=' +
        CompanyId +
        '&amount=' +
        Amount,
    )
  }

  //RECEIPT
  getInvoices2(companyid, storeid, fromdate, todate, startid, endid, invoice) {
    return this.http.get(
      this.base_url2 +
        'Receipt/Invoices2' +
        '?companyid=' +
        companyid +
        '&storeid=' +
        storeid +
        '&fromdate=' +
        fromdate +
        '&todate=' +
        todate +
        '&startid=' +
        startid +
        '&endid=' +
        endid +
        '&invoice=' +
        invoice,
    )
  }

  //GetReceiptDetails
  GetReceiptDetails(invoice, IsProd) {
    return this.http.get(
      this.base_url + 'ERP/GetReceiptDetails?invoice=' + invoice + '&IsProd=' + IsProd,
    )
  }

  //GET STORES
  getstores_temp(CompanyId) {
    return this.http.get(this.base_url + 'ERP/Getstore_temp?CompanyId=' + CompanyId)
  }

  //GET Companies
  getcom_temp() {
    return this.http.get(this.base_url + 'ERP/Getcom_temp')
  }

  //Attedance
  GetEmployeeByCom(CompanyId) {
    return this.http.get(this.base_url + 'Login/GetEmployeeByCom?companyId=' + CompanyId)
  }

  Saveatte(CompanyId, contid, date, IsCom, AttId, resid, res) {
    return this.http.get(
      this.base_url +
        'Login/SaveEmployee?companyId=' +
        CompanyId +
        '&contid=' +
        contid +
        '&date=' +
        date +
        '&IsCom=' +
        IsCom +
        '&AttId=' +
        AttId +
        '&resid=' +
        resid +
        '&res=' +
        res,
    )
  }

  Saveatte2(contactId, companyId, shift, reason, latereason) {
    return this.http.get(
      this.base_url +
        'Login/SaveAttendanceLogs?contactId=' +
        contactId +
        '&companyId=' +
        companyId +
        '&shift=' +
        shift +
        '&reason=' +
        reason +
        '&latereason=' +
        latereason,
    )
  }

  GetReasonValues() {
    return this.http.get(this.base_url + 'Login/getresaontype')
  }

  getattenvalues(contactId, currdate) {
    return this.http.get(
      this.base_url + 'Login/getattenvalues?contactId=' + contactId + '&currdate=' + currdate,
    )
  }

  GetEmpAttendance(contactId, currdate) {
    return this.http.get(
      this.base_url + 'Login/GetEmpAttendance?contactId=' + contactId + '&currdate=' + currdate,
    )
  }

  //Send To Store
  GetSTSorder(companyId, storeId, contactId, date) {
    return this.http.get(
      this.base_url +
        'STS/GetSTSorder?companyId=' +
        companyId +
        '&storeId=' +
        storeId +
        '&contactId=' +
        contactId +
        '&date=' +
        date,
    )
  }

  SaveSTS(payload) {
    return this.http.post(this.base_url + 'STS/AddSTS', payload)
  }

  STSupdate(payload) {
    return this.http.post(this.base_url + 'STS/UPSTS', payload)
  }

  ReceiveSTSupdate(OrdId) {
    return this.http.post(this.base_url + 'STS/ReceiveSTS', OrdId)
  }

  Getstsrefer(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'STS/GetSTSReferBy?CompanyId=' + CompanyId + '&StoreId=' + StoreId,
    )
  }
  getstsaccstore(companyId) {
    return this.http.get(this.base_url + 'STS/GetAccStore?companyId=' + companyId)
  }

  // CREDIT

  GetRepaymentId(TransId, CompanyId) {
    return this.http.get(
      this.base_url + 'ERP/GetRepayByIdDirect?transId=' + TransId + '&companyId=' + CompanyId,
    )
  }

  SaveCreditEditPay(payload) {
    return this.http.post(this.base_url + 'ERP/CreditEdit', payload)
  }

  getInputvalues(CompanyId) {
    return this.http.get(this.base_url + 'ERP/GetInputValues?companyId=' + CompanyId)
  }
  GetCreditcontact(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'ERP/GetCreditContacts?CompanyId=' + CompanyId + '&StoreId=' + StoreId,
    )
  }
  Getcredit(
    CompanyId,
    billStatus,
    storeId,
    contId,
    contTypeId,
    creTypeId,
    FromAmt,
    ToAmt,
    Reference,
    fromdate,
    todate,
    transId,
  ) {
    return this.http.get(
      this.base_url +
        'ERP/GetCreditData?CompanyId=' +
        CompanyId +
        '&billStatus=' +
        billStatus +
        '&storeId=' +
        storeId +
        '&contId=' +
        contId +
        '&contTypeId=' +
        contTypeId +
        '&creTypeId=' +
        creTypeId +
        '&FromAmt=' +
        FromAmt +
        '&ToAmt=' +
        ToAmt +
        '&Reference=' +
        Reference +
        '&fromdate=' +
        fromdate +
        '&todate=' +
        todate +
        '&transId=' +
        transId,
    )
  }

  SaveCredit(payload) {
    return this.http.post(this.base_url + 'ERP/SaveCredit', payload)
  }

  GetCreditDetails(transactionId) {
    return this.http.get(this.base_url + 'ERP/GetDetailbyId?Id=' + transactionId)
  }
  GetRepay(contactid, CompanyId) {
    return this.http.get(
      this.base_url + 'ERP/GetRepayById?Id=' + contactid + '&companyId=' + CompanyId,
    )
  }
  DeleteCreditPay(delTransid, delBillid) {
    return this.http.get(
      this.base_url + 'ERP/DeletePay?transid=' + delTransid + '&billid=' + delBillid,
    )
  }
  SaveRepay(payload) {
    return this.http.post(this.base_url + 'ERP/repay', payload)
  }

  GetRepayIndex(
    CompanyId,
    storeId,
    contId,
    contTypeId,
    FromAmt,
    ToAmt,
    Reference,
    fromdate,
    todate,
    transId,
  ) {
    return this.http.get(
      this.base_url +
        'ERP/CreditRepayIndex?companyId=' +
        CompanyId +
        '&storeId=' +
        storeId +
        '&contId=' +
        contId +
        '&contTypeId=' +
        contTypeId +
        '&FromAmt=' +
        FromAmt +
        '&ToAmt=' +
        ToAmt +
        '&Reference=' +
        Reference +
        '&fromdate=' +
        fromdate +
        '&todate=' +
        todate +
        '&transId=' +
        transId,
    )
  }

  RepayEdit(payload) {
    return this.http.post(this.base_url + 'ERP/EditRepay', payload)
  }

  GetRepayEdit(TransId, ContId, CompanyId) {
    return this.http.get(
      this.base_url +
        'ERP/GetRepayEditById?TransId=' +
        TransId +
        '&ContId=' +
        ContId +
        '&companyId=' +
        CompanyId,
    )
  }

  RepayDetails(TransId) {
    return this.http.get(this.base_url + 'ERP/CreditDetilRepay?id=' + TransId)
  }

  DeleteRepaydata(transid, companyId, amount) {
    return this.http.get(
      this.base_url +
        'ERP/DeleteRepaydata?transid=' +
        transid +
        '&companyId=' +
        companyId +
        '&amount=' +
        amount,
    )
  }

  GetbankaccountStore(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'ERP/GetBankAccountsByStore?companyId=' + CompanyId + '&storeId=' + StoreId,
    )
  }

  // Storepaymenttype----------------

  getStorePaymentType(StoreId) {
    return this.http.get(this.base_url + 'Menu/getStorePaymentType_Retail?storeId=' + StoreId)
  }

  AddStorepayment(spt) {
    return this.http.post(this.base_url + 'Menu/insertSpt_Retail', spt)
  }

  UpdateStorepayment(paymentType) {
    return this.http.post(this.base_url + 'Menu/updateSpt_Retail', paymentType)
  }

  StorePaymentDeactive(Id) {
    return this.http.get(this.base_url + 'Menu/StorePymtDeactive?id=' + Id)
  }
  // Category active
  Categoryupdate(Id, active, CompanyId) {
    return this.http.get(
      this.base_url +
        'Menu/UpdatecatAct?Id=' +
        Id +
        '&active=' +
        active +
        '&CompanyId=' +
        CompanyId,
    )
  }
  //Category

  GetCategory(CompanyId, type) {
    return this.http.get(
      this.base_url + 'Menu/getcategories?CompanyId=' + CompanyId + '&type=' + type,
    )
  }

  AddCategory(Category) {
    return this.http.post(this.base_url + 'Menu/addcategory', Category)
  }

  Updatecategory(Category) {
    return this.http.post(this.base_url + 'Menu/updatecategory', Category)
  }

  //Getproduct

  GetProduct(CompanyId) {
    return this.http.get(this.base_url + 'Menu/GetProducts?companyId=' + CompanyId)
  }

  //Products------------
  GetProd(CompanyId) {
    return this.http.get(this.base_url + 'Menu/getProducts_retail?companyId=' + CompanyId)
  }

  importProduct(Id, data) {
    return this.http.post(this.base_url + 'Menu/ImportProduct?companyId=' + Id, data)
  }

  // getfactorystores(CompanyId) {
  //   return this.http.get(this.base_url + 'Menu/GetFactoryStore?companyId=' + CompanyId)
  // }

  productActupdate(Id, active, CompanyId) {
    return this.http.get(
      this.base_url +
        'Menu/UpdateProdAct?Id=' +
        Id +
        '&active=' +
        active +
        '&CompanyId=' +
        CompanyId,
    )
  }

  GetProductEditInd(prodid, companyId) {
    return this.http.get(
      this.base_url + 'Menu/GetProductEditInd?prodid=' + prodid + '&companyId=' + companyId,
    )
  }

  Addproduct(product) {
    return this.http.post(this.base_url + 'Menu/insertProduct_Retail', product)
  }

  UpdateProduct(product) {
    return this.http.post(this.base_url + 'Menu/updateProduct_Retail', product)
  }

  //Add Location

  getLocation(CompanyId) {
    return this.http.get(this.base_url + 'Store/getLocation_Reatil?companyId=' + CompanyId)
  }

  //Vendors---------------------------
  GetVendor(CompanyId) {
    return this.http.get(this.base_url + 'Menu/getVendor_retail?companyId=' + CompanyId)
  }

  AddVendor(vendor) {
    return this.http.post(this.base_url + 'Menu/insertVendor_Retail', vendor)
  }

  UpdateVendor(vendor) {
    return this.http.post(this.base_url + 'Menu/updateVendor_retail', vendor)
  }

  AddLocation(locate) {
    return this.http.post(this.base_url + 'Store/insertLocation_Retail', locate)
  }

  UpdateLocation(location) {
    return this.http.post(this.base_url + 'Store/updateLocation_Retail', location)
  }

  DeActivate(sid) {
    return this.http.get(this.base_url + 'Store/updateisActive_stores_Retail?sid=' + sid)
  }

  //Option-Group--------------------

  Getoptiongroups(CompanyId) {
    return this.http.get(this.base_url + 'Menu/Get?companyId=' + CompanyId)
  }

  optionsActive(Id, active) {
    return this.http.get(this.base_url + 'Menu/UpdateAct?Id=' + Id + '&active=' + active)
  }

  Editoption(Id) {
    return this.http.get(this.base_url + 'Menu/GetById?Id=' + Id)
  }

  getCategorymapped(CompanyId) {
    return this.http.get(this.base_url + 'Menu/CategoryIndex?companyid=' + CompanyId)
  }

  getMapedProducts(CompanyId, optionGroupId) {
    return this.http.get(
      this.base_url +
        'Menu/getMapedProducts?companyId=' +
        CompanyId +
        '&optionGroupId=' +
        optionGroupId,
    )
  }

  optionBulksave(OptionGroupId, CompanyId, ProductIds) {
    return this.http.post(
      this.base_url + 'Menu/BulkSave?OptionGroupId=' + OptionGroupId + '&CompanyId=' + CompanyId,
      ProductIds,
    )
  }

  Saveoption(data) {
    return this.http.post(this.base_url + 'Menu/CreateOption', data)
  }

  UpdateOption(data) {
    return this.http.post(this.base_url + 'Menu/Update', data)
  }

  // Store-Products Modified

  getstrproducts(CompanyId, storekey) {
    return this.http.get(
      this.base_url + 'Menu/GetStoreProd?CompanyId=' + CompanyId + '&storekey=' + storekey,
    )
  }

  STRprodUpdate(payload) {
    return this.http.post(this.base_url + 'Menu/UpdateSTRPrice', payload)
  }
  UpdateStoreoption(payload) {
    return this.http.post(this.base_url + 'Menu/UpdateOPTPrice', payload)
  }

  Gettax(CompanyId) {
    return this.http.get(this.base_url + 'Menu/GetTaxGrp?CompanyId=' + CompanyId)
  }

  Addtax(taxgroup) {
    return this.http.post(this.base_url + 'Menu/AddTaxGrp', taxgroup)
  }

  Updatetax(taxgroup) {
    return this.http.post(this.base_url + 'Menu/UpdateTaxGrp', taxgroup)
  }

  //Stock Transfer
  SaveInitialStock(payload) {
    return this.http.post(this.base_url + 'Stock/AddInitialStock', payload)
  }

  DeleteStkUpd(StkUpdId, action) {
    return this.http.get(
      this.base_url + 'Stock/DeleteStkUpde?StkUpdId=' + StkUpdId + '&Checkdatas=' + action,
    )
  }

  DeleteStockUpdate(Id) {
    return this.http.get(this.base_url + 'Stock/DeleteDraftItem?Id=' + Id)
  }

  GetUpdateMoreValues(StoreId, date, catId, supId, prodname, proddes, action3) {
    return this.http.get(
      this.base_url +
        'Stock/GetUpdateMore?storeId=' +
        StoreId +
        '&date=' +
        date +
        '&catId=' +
        catId +
        '&supId=' +
        supId +
        '&prodname=' +
        prodname +
        '&proddes=' +
        proddes +
        '&Checkdata=' +
        action3,
    )
  }

  GetStockValues(
    CompanyId,
    StoreId,
    MinStr,
    MinEnd,
    MaxStr,
    MaxEnd,
    Supplierid,
    UsageCheck,
    NumberRecord,
    prodid,
    isday,
    groupId,
  ) {
    return this.http.get(
      this.base_url +
        'Stock/GetStocksNew?companyId=' +
        CompanyId +
        '&storeid=' +
        StoreId +
        '&minstr=' +
        MinStr +
        '&minend=' +
        MinEnd +
        '&maxstr=' +
        MaxStr +
        '&maxend=' +
        MaxEnd +
        '&supplierid=' +
        Supplierid +
        '&usagecheck=' +
        UsageCheck +
        '&numRecord=' +
        NumberRecord +
        '&prodId=' +
        prodid +
        '&IsDay=' +
        isday +
        '&groupId=' +
        groupId,
    )
  }

  // SaveStoctReOrder(payload) {
  //   return this.http.post(this.base_url + 'Stock/SaveReOrder', payload)
  // }

  SaveStoctReOrder(payload) {
    return this.http.post(this.base_url + 'Stock/SaveReOrderNew', payload)
  }

  GetCate(CompanyId) {
    return this.http.get(this.base_url + 'Stock/GetCategory?CompanyId=' + CompanyId)
  }

  GetStockUpdateValues(StoreId, date, catId, supId, prodname, proddes) {
    return this.http.get(
      this.base_url +
        'Stock/GetStockUpdIndex?storeId=' +
        StoreId +
        '&date=' +
        date +
        '&catId=' +
        catId +
        '&supId=' +
        supId +
        '&prodname=' +
        prodname +
        '&proddes=' +
        proddes,
    )
  }

  GetStockQty(CompanyId, StoreId, ProductId, SupplierId, IsDay) {
    return this.http.get(
      this.base_url +
        'Stock/GetStockAutoQty?CompanyId=' +
        CompanyId +
        '&StoreId=' +
        StoreId +
        '&ProductId=' +
        ProductId +
        '&SupplierId=' +
        SupplierId +
        '&IsDay=' +
        IsDay,
    )
  }

  SaveStockUpdate(payload) {
    return this.http.post(this.base_url + 'Stock/SaveStockDraft', payload)
  }

  SaveStockDraftAuto(payload) {
    return this.http.post(this.base_url + 'Stock/SaveStockDraftAuto', payload)
  }
  GetAutoOrderUpdate(CompanyId, StoreId, Supplier, ordDate, CateId) {
    return this.http.get(
      this.base_url +
        'Stock/GetAutoOrdIndex?companyId=' +
        CompanyId +
        '&storeid=' +
        StoreId +
        '&supplierId=' +
        Supplier +
        '&ordDate=' +
        ordDate +
        '&CateId=' +
        CateId,
    )
  }

  GetStockStores(CompanyId, StoreId) {
    return this.http.get(
      this.base_url + 'Stock/GetSupplierStores?companyId=' + CompanyId + '&storeid=' + StoreId,
    )
  }

  SaveAutoOrder(payload) {
    return this.http.post(this.base_url + 'Stock/SaveAutoOrder', payload)
  }
  SaveAutoOrderNew(payload) {
    return this.http.post(this.base_url + 'Stock/SaveAutoOrderNew2', payload)
  }
  GetAutoIndexMain(CompanyId, StoreId, supplierId, frmdate, todate, OrdId) {
    return this.http.get(
      this.base_url +
        'Stock/GetAutoOrdIndexMain?companyId=' +
        CompanyId +
        '&storeid=' +
        StoreId +
        '&supplierId=' +
        supplierId +
        '&frmdate=' +
        frmdate +
        '&Todate=' +
        todate +
        '&OrdId=' +
        OrdId,
    )
  }
  deleteautoitem(itemid) {
    return this.http.get(this.base_url + 'Stock/DeleteOrdItemsAuto?itemid=' + itemid)
  }

  fetchDenominationEntries(storeid, date, companyid, entrytypeid) {
    return this.http.get(
      this.base_url2 +
        `Denomination/getDenomEntry?storeid=${storeid}&date=${date}&companyid=${companyid}&entrytypeid=${entrytypeid}`,
    )
  }

  denominationTypes() {
    return this.http.get(this.base_url2 + `Denomination/GetDenominationTypes`)
  }
  denomEntryReport(companyid: number, from: string, to: string, margin: number) {
    return this.http.get(
      this.base_url2 +
        `Denomination/denomReport_?companyid=${companyid}&from=${from}&to=${to}&margin=${margin}`,
    )
  }
  getStores(compId) {
    var formURL = this.base_url2
    var formURL = this.base_url2 + 'Stores/Get?CompanyId=' + compId
    return this.http.get(formURL)
  }

  saveopeningbalance(storeid, ob, ex) {
    return this.http.get(
      this.base_url2 +
        'Stores/OpeningBalance?storeid=' +
        storeid +
        '&openingbalance=' +
        ob +
        '&expense=' +
        ex,
    )
  }
  //m

  getRecOrders(
    indexType,
    companyId,
    storeId,
    date,
    fromDate,
    toDate,
    receiver,
    supplier,
    orderStatusId,
    Orderid,
    InvoiceNo,
    billStatusId,
  ) {
    return this.http.get(
      this.base_url +
        'ERP/getRecOrders' +
        '?indexType=' +
        indexType +
        '&companyId=' +
        companyId +
        '&storeId=' +
        storeId +
        '&date=' +
        date +
        '&fromDate=' +
        fromDate +
        '&toDate=' +
        toDate +
        '&receiver=' +
        receiver +
        '&supplier=' +
        supplier +
        '&orderStatusId=' +
        orderStatusId +
        '&Orderid=' +
        Orderid +
        '&InvoiceNo=' +
        InvoiceNo +
        '&billStatusId=' +
        billStatusId,
    )
  }

  GetSTStrans(indexType, companyId, storeId, sender, receiver, fromDate, toDate, transId) {
    return this.http.get(
      this.base_url +
        'STS/GetSTStrans' +
        '?indexType=' +
        indexType +
        '&companyId=' +
        companyId +
        '&storeId=' +
        storeId +
        '&sender=' +
        sender +
        '&receiver=' +
        receiver +
        '&fromDate=' +
        fromDate +
        '&toDate=' +
        toDate +
        '&transId=' +
        transId,
    )
  }

  //New IO Q
  DelteOrdItems(ItemId) {
    return this.http.get(this.base_url + 'ERP/DeleteItems?OiId=' + ItemId)
  }

  //MC PROD

  getcompanies() {
    return this.http.get(this.base_url + 'Company/Getcompanies')
  }

  GetChartData(Product_Id, Supplier_Id) {
    return this.http.get(
      this.base_url + 'ERP/getProdChart?Product_Id=' + Product_Id + '&Supplier_Id=' + Supplier_Id,
    )
  }

  Getmcprod(companyId, supplierId, productId, fromDate, toDate) {
    return this.http.get(
      this.base_url +
        'ERP/getOrdVendorSort?companyId=' +
        companyId +
        '&supplierId=' +
        supplierId +
        '&productId=' +
        productId +
        '&frmdate=' +
        fromDate +
        '&Todate=' +
        toDate,
    )
  }

  GetMaintType() {
    return this.http.get(this.base_url + 'Store/Get_MaintType')
  }
  StoreMaintenance_Save(payload: any) {
    return this.http.post(this.base_url + 'Store/StoreMaintenance_Save', payload)
  }
  StoreMaintenance_Update(payload: any) {
    return this.http.post(this.base_url + 'Store/StoreMaintenance_Update', payload)
  }
  Get_index_data(typeid, storeid, fromamt, toamt, fromdate, todate) {
    return this.http.get(
      this.base_url +
        'Store/StoreMaintenance_Get_Index?typeid=' +
        typeid +
        '&storeid=' +
        storeid +
        '&fromamt=' +
        fromamt +
        '&toamt=' +
        toamt +
        '&fromdate=' +
        fromdate +
        '&todate=' +
        todate,
    )
  }
  Save_MaintenanceType(companyid, description, isactive) {
    return this.http.get(
      this.base_url +
        'Store/StoreMaintenance_Save_MaintenanceType?companyid=' +
        companyid +
        '&description=' +
        description +
        '&isactive=' +
        isactive,
    )
  }
  Get_MaintenanceType(companyid) {
    return this.http.get(
      this.base_url + 'Store/StoreMaintenance_Get_MaintenanceType?companyid=' + companyid,
    )
  }
  ChangeStatus(id) {
    return this.http.get(this.base_url + 'Store/StoreMaintenance_Change_Isactive?id=' + id)
  }
  StoreMaintence_Delete(id) {
    return this.http.get(this.base_url + 'Store/StoreMaintence_Delete?id=' + id)
  }

  BulckReceiveSTSupdate(OrdId) {
    return this.http.post(this.base_url + 'STS/BulkReceiveSTS', OrdId)
  }

  getclosingTran(CompanyId, StoreId, Date, BankAccountId) {
    return this.http.get(
      this.base_url +
        'Company/GetClosingTransAcc?companyId=' +
        CompanyId +
        '&storeId=' +
        StoreId +
        '&date=' +
        Date +
        '&BankAccountId=' +
        BankAccountId,
    )
  }

  SavebankClosing(payload) {
    return this.http.post(this.base_url + 'Company/SaveAccountTrans', payload)
  }

  //------------------------------------------------------------------------------------------------------------
  //DUMMY
  getdbdata(dbnames) {
    return this.http.post(this.dburl + 'getdbdata', dbnames)
  }

  getpreorderbyinvoice(invoiceno, id) {
    return this.http.get(
      this.dburl + 'getpreorderby_invoiceno?invoiceno=' + invoiceno + '&id=' + id,
    )
  }

  getorderjson(orderid) {
    return this.http.get(this.base_url2 + 'Receipt/GetOrderJson?orderid=' + orderid)
  }

  // cancelorder(orderid, payload) {
  //   return
  // }

  getBarcodeProduct(CompanyId, storeId) {
    return this.http.get(
      this.base_url1 + 'Product/getbarcodeproduct?CompanyId=' + CompanyId + '&storeId=' + storeId,
    )
  }

  getstores(compid) {
    return this.http.get(this.base_url1 + 'Internal/getStoreList?CompanyId=' + compid)
  }

  getorder(ordId) {
    return this.http.post(this.base_url1 + 'Internal/getOrderList', ordId)
  }

  updateCustomer(payload) {
    return this.http.put(this.base_url1 + 'Customer/updateData', payload)
  }

  addCustomers(payload) {
    return this.http.post(this.base_url1 + 'Customer/addData', payload)
  }

  deleteItem(Id) {
    return this.http.post(this.base_url1 + 'Internal/DeleteOrder', Id)
  }

  getOrderIdinternal(OrdId) {
    return this.http.get(this.base_url1 + 'Internal/internalordbyid?orderid=' + OrdId)
  }

  getorderlist(storeId) {
    return this.http.get(
      this.base_url1 + 'Internal/getOrderList?storeId=' + storeId + '&numRecords=50',
    )
  }

  getstoreIdInternal(CompanyId, Id) {
    return this.http.get(
      this.base_url1 + 'Internal/GetstorebyId?CompanyId=' + CompanyId + '&Id=' + Id,
    )
  }

  getintprod(CompanyId, StoreId, testid) {
    return this.http.get(
      this.base_url1 +
        'Internal/GetInternalproduct?CompanyId=' +
        CompanyId +
        '&StoreId=' +
        StoreId +
        '&testid=' +
        testid,
    )
  }

  getstoreslist(companyid, storeid) {
    return this.http.get(
      this.base_url1 + 'Internal/getstoreslist?companyid=' + companyid + '&storeid=' + storeid,
    )
  }

  savestock(order) {
    return this.http.post(this.base_url1 + 'Internal/saveIntOrd', order)
  }

  getStockContainer(CompanyId, StoreId) {
    return this.http.get(
      this.base_url1 + 'Internal/getStockContainer?CompanyId=' + CompanyId + '&storeId=' + StoreId,
    )
  }

  getDispatchList(data) {
    return this.http.post(this.base_url1 + 'Internal/ReceiveDispatch', data)
  }

  Ordrecve(data) {
    return this.http.post(this.base_url1 + 'Internal/OrdReceive', data)
  }

  editInternalord(id) {
    return this.http.get(this.base_url1 + 'Internal/EditInternalOrd?id=' + id)
  }

  deletedata(Id) {
    return this.http.post(this.base_url1 + 'Internal/Delete', Id)
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------
}
